<template>
  <!-- <input type="search" v-model="query" class="w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg" /> -->
  <vs-input
    v-model="query"
    class="w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg"
    placeholder="Search here"
    size="large"
  />
</template>

<script>
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { createWidgetMixin } from 'vue-instantsearch';

export default {
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  props: {
    delay: {
      type: Number,
      default: 200,
      required: false,
    },
  },
  data() {
    return {
      timerId: null,
      localQuery: '',
    };
  },

  computed: {
    query: {
      get() {
        return this.localQuery;
      },
      set(val) {
        this.localQuery = val;
        if (this.timerId) {
          clearTimeout(this.timerId);
        }
        this.timerId = setTimeout(() => {
          this.state.refine(this.localQuery);
        }, this.delay);
      },
    },
  },
  methods: {
    clearQuery() {
      console.log('cearling');
      this.localQuery = '';
    },
  },
  destroyed() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },
};
</script>
