<script>
export default {
  name: 'GuideBoardTour',
  data() {
    return {
      guideBoardTourSteps: [
        {
          target: window.innerWidth > 1199 ? '.vs-sidebar-item-active' : '.navbar-custom',
          header: {
            title: '<strong>This is the Guide Board</strong>!',
          },
          content: `Here you can view a guide's profile and invite them to apply for your active jobs.<br><br>Only once have they applied to one of your active jobs, will you be able to message them from either the Chat or Applications Tab.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
        {
          target: '#navbar-credits',
          // header: {
          //   title: '<strong>This is the Guide Board</strong>!',
          // },
          content: `Selecting this dropdown will display your Job Post Credits and allow you to purchase more.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
        {
          target: '#navbar-ntfs',
          // header: {
          //   title: '<strong>This is the Guide Board</strong>!',
          // },
          content: `From here you can view your Notifications and visit the Notifications Page.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
        {
          target: '#navbar-menu-dropdown',
          // header: {
          //   title: '<strong>This is the Guide Board</strong>!',
          // },
          content: `Selecting this image will open a dropdown menu from where you can access your Profile, the Chat Tab, and logout from your account.`,
          params: {
            enableScrolling: false,
            placement: 'auto-start',
          },
        },
      ],
      tourCallbacks: {
        onSkip: this.markTourAsSeen,
        onFinish: this.markTourAsSeen,
      },
    };
  },
  mounted() {
    const employer = this.$store.getters['employerModule/getUser'];
    if (!employer.dashboardTours || !employer.dashboardTours.guideBoardTourSeen) {
      setTimeout(() => {
        try {
          this.$tours['guideBoardTour'].start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
  },
  methods: {
    markTourAsSeen() {
      this.$store.dispatch('employerModule/patch', { dashboardTours: { guideBoardTourSeen: true } });
    },
  },
};
</script>
<template>
  <v-tour name="guideBoardTour" :steps="guideBoardTourSteps" :callbacks="tourCallbacks"></v-tour>
</template>

<style lang="scss">
.v-step,
.v-step__header,
.v-step__arrow,
.v-step__arrow::before {
  background: #adca63 !important;
}
.v-step {
  border-radius: 10px !important;
  z-index: 99999 !important;
}
.v-step__header {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  margin-top: 0 !important;
}

.v-step__button {
  border-radius: 5px !important;
  border-top-width: 0.1rem !important;
  font-size: 1rem !important;
}
</style>
